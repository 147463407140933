<script>
import LeftBubble from "@/components/customer/body/conversation/parts/LeftBubble.vue";
import { loader } from "@/common/mixins/mix_loader";
import { actions } from "@/common/mixins/mix_actions";
import { clientzone_url } from "@/common/config";

export default {
  name: "ActionPinVerification",
  mixins: [actions, loader],
  components: { LeftBubble },
  props: {
    message: {
      required: true,
    },
  },
  data() {
    return {
      is_submitted: false,
      clientzone_url,
      pin: "",
      domain: "",
    };
  },
  methods: {
    async onCheck() {
      try {
        if (this.is_deleted) {
          throw "Forbidden";
        }

        if (this.pin.length !== 4) {
          throw "PIN belum lengkap ";
        }

        if (this.is_teknis && this.domain.length < 4) {
          throw "Domain belum diisi, minimal 4 karakter";
        }
        let message = this.is_teknis
          ? `PIN clientzone saya ${this.pin} dan domain ${this.domain}`
          : `PIN clientzone saya ${this.pin}`;

        this.is_submitted = true;
        await this.submit_action_command(message, "pin_verification_message", {
          action_message: this.pin + "|" + this.domain,
        });
      } catch (e) {
        this.$swal("", e, "error");
      }
    },

    async onCancel() {
      if (this.is_deleted) {
        return false;
      }

      this.is_submitted = true;
      this.loading();
      await this.cancel_action(
        this.message.uuid,
        "Saya tidak jadi melakukan verifikasi PIN"
      );
      this.loading(false);
    },
  },
  computed: {
    is_deleted() {
      return [1, "1", true].includes(this.message.deleted);
    },
    is_teknis() {
      return parseInt(this.message.staff_deptid) === 3;
    },
    is_invalid() {
      if (this.is_teknis) {
        return this.pin.length !== 4 || this.domain.length < 4;
      }

      return this.pin.length !== 4;
    },
  },
};
</script>

<template>
  <LeftBubble :message="message">
    <template v-if="is_deleted">
      <p class="mb-0 flex items-center gap-1">
        <span class="material-symbols-outlined">description</span> Form
        verifikasi PIN ClientZone
      </p>
    </template>
    <template v-else>
      <p
        v-if="message.message !== 'pin_verification'"
        v-html="message.message"
        class="font-weight-bold"
      />
      <p>
        Silahkan klik link berikut
        <a :href="`${clientzone_url}/pin`" target="_blank"
          >{{ clientzone_url }}/pin</a
        >
        untuk mendapatkan PIN support lalu masukkan angka PIN ke form di bawah
        ini:
      </p>

      <form @submit.stop.prevent="onCheck">
        <div class="form-group mb-2">
          <input
            type="text"
            v-model="pin"
            minlength="4"
            maxlength="4"
            pattern="[0-9]{4}"
            class="form-control form-control-sm"
            placeholder="PIN 4 Karakter Angka"
          />
        </div>
        <div class="form-group mb-2" v-if="is_teknis">
          <input
            type="text"
            v-model="domain"
            class="form-control form-control-sm"
            placeholder="nama domain Anda …"
          />
          <small class="text-muted"
            >* Domain / hostname vps clientzone yang akan dicek</small
          >
        </div>
        <div class="flex gap-2 mb-1">
          <button
            class="btn btn-primary rw-bg-3 btn-sm border-0"
            type="submit"
            :disabled="is_deleted || is_submitted || is_invalid"
          >
            Verifikasi
          </button>
          <button
            class="btn btn-outline-secondary btn-sm"
            type="button"
            @click="onCancel"
            :disabled="is_deleted || is_submitted"
          >
            Batal
          </button>
        </div>
        <small class="text-muted italic mt-5"
          >*Klik "Batal" untuk mengirim pesan ke staff</small
        >
      </form>
    </template>
  </LeftBubble>
</template>

<style scoped></style>
