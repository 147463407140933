<template>
  <div class="conversation">
    <icon-svg></icon-svg>
    <div
      class="flex w-full justify-center mt-2"
      v-if="getAllMessage.length >= limitShowMore && !isAllShow"
    >
      <div
        class="flex items-center rw-bg-3 text-white p-1 rounded"
        @click="btnLoadMoreMessage"
      >
        <span class="small">Load more message</span>
        <i class="material-symbols-outlined text-sm">refresh</i>
      </div>
    </div>

    <div
      v-for="(message, key) in getAllMessage"
      :key="key"
      class="rw-chat"
      :class="message.actor === 'customer' ? 'rw-chat-right' : ''"
    >
      <div class="d-flex justify-content-around my-2" v-if="isShow(key)">
        <span class="badge bg-gray-200 text-gray-600">{{
          dateInfo(message.time)
        }}</span>
      </div>

      <bot-part
        v-if="message.actor === 'system'"
        :message="message"
        :departmentReady="department"
      />

      <agent-part
        v-if="message.actor === 'staff' && message.tipe !== 'action'"
        :agentImage="image.staff"
        :message="message"
      />

      <client-part
        v-if="
          message.actor === 'customer' &&
          !['1st-message'].includes(message.tipe)
        "
        :clientImage="image.customer"
        :message="message"
      />

      <ActionPart v-if="message.tipe === 'action'" :message="message" />
    </div>

    <br />

    <LiveChatAgent />
    <CloseChatSession />
    <ContinueChat />
  </div>
</template>

<script>
import "@/assets/css/customer/rw-chat.css";
import { image } from "@/common/config";
import { mapGetters } from "vuex";
import AppConfig from "@/common/config/app.config.json";
import { clientCookies } from "@/common/mixins/mix_cookies";
import { CookiesClient } from "@/common/config";

// component | widget
import botPart from "@/components/customer/body/conversation/BotPart";
import agentPart from "@/components/customer/body/conversation/AgentPart";
import clientPart from "@/components/customer/body/conversation/ClientPart";
import { waktu, request } from "@/common/mixins/mix_helper";
import { loader } from "@/common/mixins/mix_loader";
import { actions } from "@/common/mixins/mix_actions";
import IconSvg from "@/components/widget/IconSvg";
import LiveChatAgent from "@/components/customer/modules/LiveChatAgent.vue";
import ContinueChat from "@/components/customer/modules/ContinueChat.vue";
import ActionPart from "@/components/customer/body/conversation/ActionPart.vue";
import CloseChatSession from "@/components/customer/modules/CloseChatSession.vue";

export default {
  name: "Conversation",
  mixins: [actions, waktu, request, clientCookies, CookiesClient, loader],
  components: {
    CloseChatSession,
    ActionPart,
    ContinueChat,
    LiveChatAgent,
    IconSvg,
    clientPart,
    agentPart,
    botPart,
  },
  props: {
    /**
     * --------------------------------------------------------------
     * untuk memilih / indikator department mana saja yang sedang on
     * --------------------------------------------------------------
     */
    department: {
      required: true,
    },
  },
  data() {
    return {
      image: image,
      draw: 1,
      limitShowMore: AppConfig.limitMessageClient,
      isAllShow: false,
      actionLeftSide: ["payment_confirmation"],
    };
  },
  methods: {
    /**
     * --------------------------------------------------------------
     * process request load more message from API
     * --------------------------------------------------------------
     */
    btnLoadMoreMessage() {
      let cookies = this.cookiesGet();
      if (cookies) {
        let params = {
          threadid: cookies.threadid,
          actor: "customer",
          limit: AppConfig.limitMessageClient,
          draw: this.getAllVuexAgent._drawMessage.value + 1,
          last_time: this.getAllVuexAgent._drawMessage.lastmsgid,
        };
        this.loading();
        this.API.post(this.URL.client.message_all, params)
          .then(({ data }) => {
            if (data.data.record.length !== 0) {
              if (typeof data.data.record[0].time !== "undefined") {
                this.$store.dispatch("Draw", {
                  type: "setLastMessageID",
                  value: data.data.record[0].time,
                });
                this.$store.dispatch("Draw", { type: "increase" });
              }
              this.$store.dispatch("prependMessageCustomer", data.data.record);

              /**
               * --------------------------------------------------------------
               * handle re-generate token expired
               * --------------------------------------------------------------
               */
              if (typeof data.data.token !== "undefined") {
                localStorage.setItem(
                  AppConfig.rwStorage.customer.token,
                  data.data.token
                );
              }
            } else {
              this.isAllShow = true;
              this.$swal("", "Semua pesan sudah ditampilkan", "info");
            }
          })
          .catch((error) => {
            // ada manipulasi cookies tidak ada threadid
            let message = this.handleCatchAxios("All message", error, true);
            this.mixSwalBlock(message);
          })
          .finally(() => {
            this.loading(false);
          });
      } else {
        /**
         * --------------------------------------------------------------
         * clear cookies & localstorage & logout firebase & reload
         * --------------------------------------------------------------
         */
        this.$store
          .dispatch("FbAuthSignOut")
          .then(() => {
            this.cookiesRemove(); // make sure remove cookies
            localStorage.removeItem(AppConfig.rwStorage.customer.token);
            localStorage.removeItem(AppConfig.rwStorage.agent.config);
          })
          .finally(() => {
            location.reload();
          });
      }
    },

    /**
     * --------------------------------------------------
     * trigger handle client afk livechat
     * --------------------------------------------------
     */
    getThreadInfo() {
      this.API.get(this.URL.client.thread).then(({ data }) => {
        this.$store.dispatch("setAllSubThread", data.sub);
        let params = {
          isClosed: data.isclosed,
          isContinueChatEnabled: data.is_continue_enable,
          isTakeOver: data.istakeover,
          isChatAreaEnabled: data.ischatenable,
          isVerified: data.isverified,
        };
        this.cookiesSyncUpdate(params);
      });
    },

    isShow(index) {
      if (index === 0) {
        return true;
      }
      const prevDate = this.mixEpochToFullDate(
        this.getAllMessage[index - 1].time,
        false
      );
      const currentDate = this.mixEpochToFullDate(
        this.getAllMessage[index].time,
        false
      );

      return prevDate !== currentDate;
    },

    dateInfo(epochtime) {
      const date = this.mixEpochToFullDate(epochtime, false);
      const date_message = new Date().toISOString().split("T")[0];
      const today = new Date(epochtime * 1000).toISOString().split("T")[0];

      if (today === date_message) {
        return "Hari ini";
      }
      return date;
    },
  },
  computed: {
    ...mapGetters(["getAllMessage", "getAllVuexAgent"]),
  },
  async mounted() {
    if (this.getAllMessage.length === 0) {
      await this.btnLoadMoreMessage();
      setTimeout(async () => {
        await this.getThreadInfo();
      }, 500);
    }
  },
};
</script>

<style scoped>
.conversation {
  line-height: 1.3;
  font-size: 14px;
}
.btn-load-more {
  cursor: pointer;
}
.btn-load-more:hover {
  background-color: #00aae5;
}
.icon-sync {
  width: 10px;
  height: 16px;
  fill: white;
}
</style>

<style lang="scss">
svg.qc-icon {
  fill: #979797;
  height: 2rem;
  width: 2rem;
}
a:hover {
  text-decoration: none;
}
</style>
