<script>
import { waktu } from "@/common/mixins/mix_helper";
import { image } from "@/common/config";

export default {
  name: "LeftBubble",
  mixins: [waktu],
  props: {
    message: {
      required: true,
    },
    showTime: {
      default: false,
    },
  },
  data() {
    return {
      image: image,
    };
  },
  methods: {
    getFirstName: function (fullName) {
      let uname = fullName.split(" ");
      return uname[0];
    },
  },
  computed: {
    is_agent() {
      return this.message.actor === "staff";
    },
  },
};
</script>

<template>
  <div>
    <div class="flex flex-col float-left text-center" style="max-width: 2rem">
      <img
        class="rw-chat-img mt-2 h-8"
        :src="is_agent ? image.staff : image.bot"
        :alt="message.name"
        :title="message.name"
      />
      <div class="text-break small">
        {{ is_agent ? getFirstName(message.name) : message.name }}
      </div>
    </div>
    <div class="rw-chat-text py-2">
      <slot></slot>

      <div class="float-right" v-if="showTime">
        <small>{{ mixEpochToHours(message.time) }}</small>
      </div>
      <div class="clear-both" />
    </div>
  </div>
</template>
