<script>
import { util, waktu } from "@/common/mixins/mix_helper";

export default {
  name: "BotPartBubble",
  mixins: [util, waktu],
  props: {
    // tipe: {
    //   required: true,
    // },
    message: {
      required: true,
    },
  },
};
</script>

<template>
  <div id="botPartBubble">
    <div class="float-left text-center">
      <img
        class="rw-chat-img"
        src="img/rw/bot.png"
        alt="bot"
        title="Rumahweb"
      />
      <div>
        <small>Bot</small>
      </div>
    </div>
    <div class="rw-chat-text">
      <div v-html="nl2br(message.message)" class="float-left" />
      <div class="float-right">
        <small v-if="message.tipe === 'agent-close'">{{
          mixEpochToFullDate(message.time)
        }}</small>
        <small v-else>{{ mixEpochToHours(message.time) }}</small>
      </div>
      <div style="clear: both"></div>
    </div>
  </div>
</template>

<style scoped>
#botPartBubble {
  --botColor: #f8faff;
}
.rw-chat-text {
  background-color: var(--botColor);
  /*color: #fff;*/
}
.rw-chat-text:before,
.rw-chat-text:after {
  border-right-color: var(--botColor);
}
</style>
