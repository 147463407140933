<script>
import { mapGetters } from "vuex";
import { alert, request } from "@/common/mixins/mix_helper";
import { loader } from "@/common/mixins/mix_loader";
import { clientCookies } from "@/common/mixins/mix_cookies";

export default {
  name: "CloseChatSession",
  mixins: [alert, clientCookies, loader, request],
  methods: {
    onEnded() {
      this.loading();
      this.API.post(this.URL.client.end_workflow, {
        threadid: -1,
      })
        .then(() => {
          this.mixToast("Sesi percakapan telah berakhir", {
            position: "center",
          });
        })
        .catch((err) => {
          if (typeof err.response === "undefined") {
            this.$swal("Error", err.message, "error");
          } else {
            this.$swal("Error", err.response.data.message, "error");
          }
        })
        .finally(() => {
          this.loading(false);
        });
    },
  },
  computed: {
    ...mapGetters(["getConfigJs"]),
  },
};
</script>

<template>
  <div
    class="flex items-center justify-end w-full gap-1"
    v-if="!getConfigJs.isTakeOver && !getConfigJs.isClosed"
  >
    <i class="material-symbols-outlined text-sm text-danger"
      >do_not_disturb_on</i
    >
    <a href="#" class="small" @click.stop.prevent="onEnded()"
      >Akhiri sesi percakapan</a
    >
  </div>
</template>

<style scoped></style>
