<script>
import LeftBubble from "@/components/customer/body/conversation/parts/LeftBubble.vue";
import { actions } from "@/common/mixins/mix_actions";
import { loader } from "@/common/mixins/mix_loader";

export default {
  name: "ActionBillingInformation",
  mixins: [actions, loader],
  components: { LeftBubble },
  props: {
    message: {
      required: true,
    },
  },
  data() {
    return {
      domain: "",
      is_submitted: false,
    };
  },
  methods: {
    async onCancel() {
      if (this.is_deleted) {
        return false;
      }

      this.is_submitted = true;
      this.loading();
      await this.cancel_action(
        this.message.uuid,
        "Saya tidak jadi melihat informasi tagihan"
      );
      this.loading(false);
    },

    async onSubmit() {
      try {
        if (this.is_deleted) {
          throw "Forbidden";
        }
        if (this.domain.length === 0 || !this.is_domain_valid) {
          throw "Format domain tidak valid";
        }

        this.is_submitted = true;
        await this.submit_action_command(
          `Informasi tagihan domain ${this.domain}`,
          "billing_information",
          {
            action_message: this.domain,
          }
        );
      } catch (e) {
        this.$swal("", e, "error");
      }
    },
  },
  computed: {
    is_deleted() {
      return [1, "1", true].includes(this.message.deleted);
    },
    is_domain_valid() {
      const domainRegex = /^(?!:\/\/)([a-zA-Z0-9-]{1,63}\.)+[a-zA-Z]{2,}$/;
      return domainRegex.test(this.domain);
    },
  },
};
</script>

<template>
  <LeftBubble :message="message">
    <template v-if="is_deleted">
      <p class="mb-0 flex items-center gap-1">
        <span class="material-symbols-outlined">description</span> Form
        Informasi Tagihan
      </p>
    </template>
    <template v-else>
      <p
        v-if="message.message !== 'billing_information'"
        v-html="message.message"
        class="font-weight-bold"
      />
      <p class="mb-2">
        Silakan masukan nama domain untuk melihat informasi tagihan
      </p>
      <form action="">
        <div class="form-group">
          <input
            type="text"
            class="form-control form-control-sm"
            placeholder="Contoh rumahweb.com"
            v-model="domain"
          />
          <small
            class="text-danger"
            v-if="domain.length > 0 && !is_domain_valid"
            >Format domain <b>{{ domain }}</b> belum benar</small
          >
          <small
            class="text-success"
            v-if="domain.length > 0 && is_domain_valid"
            >Format domain <b>{{ domain }}</b> benar</small
          >
        </div>
        <div class="flex gap-3 mb-1">
          <button
            class="btn btn-primary rw-bg-3 border-0 btn-sm"
            type="submit"
            :disabled="is_deleted || is_submitted || !is_domain_valid"
            @click="onSubmit"
          >
            Submit
          </button>
          <button
            class="btn btn-outline btn-outline-secondary btn-sm"
            type="button"
            @click="onCancel"
            :disabled="is_deleted || is_submitted"
          >
            Batal
          </button>
        </div>
        <small class="text-muted italic"
          >*Klik "<b>Batal</b>" untuk mengirim pesan</small
        >
      </form>
    </template>
  </LeftBubble>
</template>

<style scoped></style>
