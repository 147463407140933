<template>
  <div class="flex base-textarea bg-gray-100 border-top h-content w-full">
    <div class="flex items-center">
      <div
        v-b-tooltip.hover
        title="Emot Icon"
        class="text-gray-500 hover:text-yellow-600 pt-1"
        @click="$store.dispatch('SetEmoji', { open: !getEmoji.open })"
      >
        <span class="material-symbols-outlined">add_reaction</span>
      </div>

      <div
        v-b-tooltip.hover
        title="Lampirkan File"
        class="relative cursor-pointer text-gray-500 hover:text-blue-400 pt-1"
      >
        <input
          type="file"
          @change="onChooseFile"
          ref="file"
          class="absolute w-full opacity-0 cursor-pointer"
        />
        <span class="material-symbols-outlined">attach_file</span>
      </div>
    </div>

    <div
      class="flex flex-1 rounded content-center items-center border-1 my-2 bg-white"
    >
      <input
        type="text"
        class="w-full pr-2"
        ref="message"
        v-model="message"
        :placeholder="placeholder"
        :disabled="getTextArea.disable"
        @keyup.enter="sendMessage()"
        @paste="onPaste"
      />
      <div
        @click="sendMessage"
        v-b-tooltip.hover
        title="Kirim Pesan"
        class="bg-gray-100 flex content-center p-1 mr-1 rounded hover:bg-blue-400 hover:text-white cursor-pointer"
      >
        <span class="material-symbols-outlined">send</span>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { loader } from "@/common/mixins/mix_loader";
import { mapGetters } from "vuex";
import { waktu, alert, util } from "@/common/mixins/mix_helper";
import { clientCookies } from "@/common/mixins/mix_cookies";
import AppConfig from "@/common/config/app.config.json";

export default {
  name: "sendMessage",
  mixins: [loader, clientCookies, alert, util],
  data() {
    return {
      message: "",
    };
  },
  methods: {
    /**
     * --------------------------------------------------------------
     * if copy and paste image file in text area / input area
     * --------------------------------------------------------------
     */
    onPaste: function (e) {
      let files = e.clipboardData.files;
      if (files.length) {
        this.onChooseFile(files[0], true);
      }
    },

    /**
     * --------------------------------------------------------------
     * send message
     * --------------------------------------------------------------
     */
    sendMessage: function () {
      let message = this.generateTextMessage();

      if (this.message.trim() !== "") {
        this.message = "";
        this.$store.dispatch("MessagePush", message);

        setTimeout(() => {
          let elm = $("#chat");
          elm.animate(
            {
              scrollTop: parseInt(elm[0].scrollHeight),
            },
            500
          );
        }, 400);

        this.$store
          .dispatch("customerSendMessage", message)
          .then(({ data }) => {
            this.cookiesSyncUpdate({ rating: { uuid: null, endtime: null } });

            // if token has been expired
            if (typeof data.data.token !== "undefined") {
              localStorage.setItem(
                AppConfig.rwStorage.customer.token,
                data.data.token
              );
            }

            /**
             * --------------------------------------------------------------
             * check on success req API
             * --------------------------------------------------------------
             */
            if (typeof message.status !== "undefined") {
              message["status"] = "sent";
            }
            message["time"] = data.data.time ? data.data.time : message["time"];
            this.getAllMessage.filter((all, index) => {
              if (all.uuid === message.uuid) {
                this.$store.dispatch("MessageUpdate", {
                  index: index,
                  record: message,
                });
              }
            });

            /**
             * --------------------------------------------------------------
             * trigger get info jika flag nya false
             * --------------------------------------------------------------
             */
            if (!this.getAllVuexClient._getInfoAgent) {
              this.$store.dispatch("AgentInfo");
              this.$store.dispatch("setFlagInfoAgent", true);
            }
          })
          .catch(() => {
            if (typeof message.status !== "undefined") {
              message["status"] = "failed";
            }
            this.getAllMessage.filter((all, index) => {
              if (all.uuid === message.uuid) {
                this.$store.dispatch("MessageUpdate", {
                  index: index,
                  record: message,
                });
              }
            });
          });
      } else {
        this.$swal("error", "Message is required", "error");
      }
    },

    /**
     * --------------------------------------------------------------
     * generate text message regular not 1st message
     * --------------------------------------------------------------
     */
    generateTextMessage() {
      let uuid = () => {
        let str = Math.random().toString(36).substring(2);
        let epoch = Math.round(Date.now() / 1000);
        return str + "." + epoch;
      };

      let roti = this.cookiesGet();
      if (roti && roti.isLogin && typeof roti.threadid !== "undefined") {
        return {
          time: waktu.methods.mixEpoch(),
          tipe: "text",
          actor: "customer",
          message: this.escape(this.message),
          uuid: uuid(),
          threadid: roti.threadid,
          status: "pending",
        };
      } else {
        // paksa refresh page karna cookies gk ada
        location.reload();
      }
    },

    /**
     * --------------------------------------------------------------
     * ketika memilih file validasi ekstensi
     * --------------------------------------------------------------
     */
    onChooseFile: function (e, paste = false) {
      let attachment = paste ? e : e.target.files[0];

      let extFiles = AppConfig.ext_allowed.file;
      let extImages = AppConfig.ext_allowed.image;
      let allowedExt = [...extFiles, ...extImages];
      if (allowedExt.includes(attachment.type)) {
        let size = Math.round(attachment.size / 1024);
        if (size > 10240) {
          this.$swal({
            title: "Error",
            html: `Ukuran file maksimal 10MB!`,
            icon: "error",
          });
        } else {
          this.loading();
          this.$store
            .dispatch("customerSendMessageFile", {
              tipe: extImages.includes(attachment.type) ? "image" : "file",
              message: attachment,
            })
            .then(({ data }) => {
              if (typeof data.data.token !== "undefined") {
                localStorage.setItem(
                  AppConfig.rwStorage.customer.token,
                  data.data.token
                );
              }
              this.$swal("Success", "Attachment terkirim", "success");
            })
            .catch(() => {
              this.$swal(
                "Error",
                `sistem kami gagal mengirim file Anda, silahkan ulangi beberapa saat lagi, atau hubungi kami ke <a href="mailto:info@rumahweb.com">info@rumahweb.com</a> untuk melaporkan masalah ini.`,
                "error"
              );
            })
            .finally(() => {
              this.loading(false);
            });
        }
      } else {
        // alert when fail
        let ext = attachment.type.split("/");
        ext = ext[1].split("."); // get last ext
        this.$swal({
          title: "Error",
          html: `<b>${ext[ext.length - 1]}</b> tidak diizinkan! <br> \
	  			<small><b>PNG | JPEG | JPG | GIF | BMP | PDF</b></small>`,
          icon: "error",
        });
      }
    },
  },
  computed: {
    ...mapGetters([
      "getTextArea",
      "getEmoji",
      "getAllMessage",
      "getAllVuexClient",
    ]),
    placeholder: function () {
      return this.getTextArea.disable
        ? this.getTextArea.msgDisable
        : this.getTextArea.msgEnable;
    },
    listenSelectEmoji() {
      return {
        open: this.$store.state.customer._emoji.open,
        select: this.$store.state.customer._emoji.select,
        emot: this.$store.state.customer._emoji.emotIcon,
      };
    },
  },
  watch: {
    listenSelectEmoji(emoji) {
      if (emoji.select) {
        this.message = this.message + " " + emoji.emot;

        this.$refs.message.focus();
        this.$store.dispatch("SetEmoji", { select: false });
      }
    },
  },
};
</script>

<style scoped>
.base-textarea {
  bottom: 0;
  padding: 0 0.5rem;
}

input[type="text"] {
  font-weight: normal;
  border-radius: 6px;
  outline: none;
  overflow: auto;
  box-sizing: border-box;
  flex: 1;
  font-size: 15px;
  line-height: 150%;
  max-height: 100%;
  resize: none;
  align-self: center;
  color: rgb(102, 102, 102);
  height: 3em;
  padding-left: 0.5em;
  background: transparent !important;
}

.btn-send svg:hover {
  fill: #00aae5;
}
</style>
