var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"float-left text-center",attrs:{"id":"agentThumb"}},[_c('img',{staticClass:"rw-chat-img",attrs:{"src":_vm.agentImage,"alt":_vm.message.name,"title":_vm.message.name}}),_c('div',{staticClass:"small text-break"},[_vm._v(_vm._s(_vm.getFirstName(_vm.message.name)))])]),_c('div',{staticClass:"rw-chat-text"},[(
        ['text', 'agent-autoreply', 'action-command'].includes(
          _vm.message.tipe
        ) ||
        (['image', 'file'].includes(_vm.message.tipe) &&
          (_vm.message.deleted === '1' || _vm.message.deleted === true))
      )?_c('div',{domProps:{"innerHTML":_vm._s(_vm.nl2br(_vm.message.message))}}):_vm._e(),(_vm.message.tipe === 'image' && _vm.message.deleted === '0')?_c('div',{staticClass:"float-left"},[_c('a',{attrs:{"href":_vm.mixGetImage(_vm.message.message),"target":"_blank"}},[_c('img',{staticStyle:{"height":"78px","max-width":"100%"},attrs:{"src":_vm.mixGetImage(_vm.message.message),"alt":_vm.message.message}})])]):_vm._e(),(_vm.message.tipe === 'file' && _vm.message.deleted === '0')?_c('div',{staticClass:"d-flex align-items-end"},[_c('svg',{staticClass:"qc-icon mt-0"},[_c('use',{attrs:{"xlink:href":"#rw-file-download"}})]),_c('a',{attrs:{"href":_vm.mixGetFiles(_vm.message.message),"target":"_blank"}},[_vm._v("Download")])]):_vm._e(),_c('div',{staticClass:"float-right"},[_c('small',[_vm._v(_vm._s(_vm.mixEpochToHours(_vm.message.time)))])]),_c('div',{staticStyle:{"clear":"both"}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }