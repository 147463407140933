<script>
import { clientMessage } from "@/common/mixins/mix_message";
import { loader } from "@/common/mixins/mix_loader";
import { clientCookies } from "@/common/mixins/mix_cookies";
import { request } from "@/common/mixins/mix_helper";
import { actions } from "@/common/mixins/mix_actions";

export default {
  name: "CommonQuestion",
  props: {
    uid: {
      required: true,
    },
    message: {
      required: true,
    },
  },
  mixins: [actions, clientCookies, clientMessage, loader, request],
  data() {
    return {
      commonQuestions: {},
    };
  },
  methods: {
    getCommonQuestion() {
      this.API.get("/client/question/department")
        .then(({ data }) => {
          this.commonQuestions = data;
          // this.commonQuestions.push({
          //   question: "Pertanyaan Lainnya",
          //   keyword: "other",
          // });
        })
        .catch(() => {
          this.commonQuestions = [];
        });
    },

    async actionProcessing(row) {
      await this.clientSendMessage(row.question);

      await this.handle_workflow(this.cookiesGet("threadid"), {
        keyword: row.keyword,
        uid: this.uid,
      });
    },
  },
  computed: {
    isJson() {
      try {
        return JSON.parse(this.message);
      } catch (e) {
        return false;
      }
    },
  },
  mounted() {
    if (!this.isJson) {
      this.getCommonQuestion();
    }
  },
};
</script>

<template>
  <div class="flex flex-column gap-2">
    <template v-if="isJson">
      <button
        class="btn btn-secondary border-0 rounded-pill d-flex"
        v-for="i in isJson"
        disabled
        :key="i"
      >
        <small v-html="i" />
      </button>
    </template>
    <template v-else>
      <button
        class="btn border-0 text-white rounded-pill rw-bg-3 d-flex"
        v-for="i in commonQuestions"
        :key="i.keyword"
        @click="actionProcessing(i)"
      >
        <small v-html="i.question" />
      </button>
    </template>
  </div>
</template>
