<template>
  <div
    id="chat"
    class="container flex-grow overflow-y-auto overflow-x-hidden pb-2"
    ref="chatArea"
  >
    <div class="d-flex justify-content-center" v-if="showAlertDC">
      <div class="alert alert-danger border-0 text-center alert-dc mt-2 w-75">
        <p class="text-sm mb-0">
          Browser Anda gagal terhubung dengan server livechat kami. Klik tombol
          <b>Refresh</b> dibawah ini
        </p>
        <a
          class="badge badge-danger rw-bg-3 mb-2"
          target="_blank"
          :href="baseChatURL"
          ><span>Refresh</span></a
        >
        <p class="text-sm mb-0">
          Jika masalah masih terjadi, akses kami di
          <span class="font-bold">
            <a href="https://chat.rumahweb.com" target="_blank"
              >chat.rumahweb.com</a
            ></span
          >
          menggunakan koneksi atau perangkat yang berbeda
        </p>
      </div>
    </div>

    <v-emoji-picker
      :emojisByRow="7"
      :emojiWithBorder="false"
      :showSearch="false"
      v-if="getEmoji.open"
      @select="selectEmoji"
    />
    <Conversation :department="departmentStatus" />
    <JumpToBottom />
  </div>
</template>

<script>
import $ from "jquery";
import Conversation from "@/components/customer/body/Conversation";
import { clientCookies } from "@/common/mixins/mix_cookies";
import { db } from "@/common/firebase/init";
import { request, waktu, alert, util } from "@/common/mixins/mix_helper";
import { mapGetters } from "vuex";
import { VEmojiPicker } from "v-emoji-picker";
import { CookiesClient } from "@/common/config";
import AppConfig from "@/common/config/app.config.json";
import { trash } from "@/common/mixins/mix_localStorage";
import { baseChatURL } from "@/common/config";
import JumpToBottom from "@/components/customer/modules/JumpToBottom.vue";

export default {
  name: "AfterLoginBody",
  mixins: [clientCookies, waktu, request, alert, CookiesClient, util, trash],
  components: {
    JumpToBottom,
    Conversation,
    VEmojiPicker,
  },
  props: {
    /**
     * --------------------------------------------------------------
     * untuk memilih / indikator department mana saja yang sedang on
     * --------------------------------------------------------------
     */
    departmentStatus: {
      required: true,
    },
  },
  data() {
    return {
      showAlertDC: false,
      baseChatURL: baseChatURL,
      fallbackInterval: null,
    };
  },
  methods: {
    /**
     * --------------------------------------------------------------
     * init created
     * --------------------------------------------------------------
     */
    initCreated: function () {
      this.$store
        .dispatch("FbAuthIsLogin")
        .then((user) => {
          /**
           * --------------------------------------------------
           * stop fallback jika refresh & berhasil login fb
           * --------------------------------------------------
           */
          this.setFallbackStop();

          /**
           * --------------------------------------------------------------
           * listen incoming message
           * --------------------------------------------------------------
           */
          let lastTime = this.getAllVuexClient._clientLastMessage.time;
          db.ref(`messages/${user.uid}`)
            .orderByChild("time")
            .startAfter(lastTime)
            .on("child_added", (response) => {
              let message = response.val();

              if (message.tipe !== "hidden-message") {
                message.deleted = "0";
                this.handleIncomingMessage(message);

                /**
                 * --------------------------------------------------------------
                 * update last time client for sort firebase
                 * --------------------------------------------------------------
                 */
                this.$store.dispatch("clientLastMessage", message);

                setTimeout(() => {
                  this.scrollToEndChat();
                }, 200);
              }
            });

          db.ref(`messages/${user.uid}`).on("child_changed", (response) => {
            const message = response.val();
            const messageIndex = this.getAllMessage.findIndex(
              (e) => e.uuid === message.uuid
            );
            if (messageIndex >= 0) {
              this.$store.dispatch("MessageUpdate", {
                index: messageIndex,
                record: { ...message },
              });
            }
          });

          /**
           * --------------------------------------------------------------
           * listen cron remove-message
           * and update local message if available
           * --------------------------------------------------------------
           */
          db.ref(`cron-customer/remove-message/${user.uid}`)
            .orderByChild("time-cron")
            .startAt(lastTime)
            .on("child_added", (response) => {
              let message = response.val();
              if (typeof message.uuid !== "undefined") {
                this.getAllMessage.filter((all, index) => {
                  if (all.uuid === message.uuid) {
                    let payload = {
                      index: index,
                      record: { message: message["message-deleted"] },
                    };
                    if (["image", "file"].includes(all.tipe)) {
                      payload.record["tipe"] = "text";
                    }
                    this.$store.dispatch("MessageUpdate", payload);
                  }
                });
              }
            });

          /**
           * --------------------------------------------------
           * listen move arsip (remove message)
           * --------------------------------------------------
           */
          db.ref(`cron-customer/move-arsip-message/${user.uid}`)
            .orderByChild("time-cron")
            .startAt(lastTime)
            .on("child_added", (response) => {
              let params = response.val();
              if (typeof params.uuid !== "undefined") {
                let newMsg = this.getAllMessage.filter((e) => {
                  return e.uuid !== params.uuid;
                });
                this.$store.dispatch("MessageSet", newMsg);
                this.$store.dispatch("ToggleTextArea", {
                  disable: false,
                });

                // set alert
                let alertUid = "customuid.123456789";
                let alertMsg = {
                  name: "Bot",
                  message: `Pesan anda telah ditutup otomatis oleh sistem karena tidak memilih departement dalam 5 menit`,
                  actor: "system",
                  tipe: "alert",
                  time: lastTime,
                  status: "sent",
                  uuid: alertUid,
                  deleted: "0",
                };
                let check = this.getAllMessage.find((e) => e.uuid === alertUid);
                if (!check) {
                  this.$store.dispatch("MessagePush", alertMsg);
                }
              }
            });

          /**
           * --------------------------------------------------
           * jika agent nge Undo close thread
           * --------------------------------------------------
           */
          db.ref(`cron-customer/undo-close-thread/${user.uid}`)
            .orderByChild("time-cron")
            .startAt(lastTime)
            .on("child_added", (response) => {
              const params = response.val();
              this.$store.dispatch("printDemo", {
                ...params,
                ...{ info: "cAL.Undo" },
              });

              let rating = {
                rating: { uuid: null, endtime: null },
              };
              this.$store.dispatch("ConfigJs", rating); // vuex
              this.cookiesAdd(rating); // js
            });

          /**
           * --------------------------------------------------
           * listen current thread change
           * --------------------------------------------------
           */
          db.ref(`threads/${user.uid}`).on("child_changed", (response) => {
            const key = response.key;
            const value = response.val();

            const allowedChange = ["close", "is_takeover", "verified"];
            if (allowedChange.includes(key)) {
              let config = {};
              switch (key) {
                case "is_takeover":
                  config.isTakeOver = value;
                  break;
                case "close":
                  config.isContinueChatEnabled = value;
                  config.isClosed = value;
                  break;
                case "verified":
                  config.isVerified = Boolean(value);
                  break;
              }

              this.cookiesSyncUpdate(config);
            }
          });
        })
        /**
         * --------------------------------------------------
         * catch gagal login firebase
         * --------------------------------------------------
         */
        .catch((error) => {
          this.$store.dispatch("printDemo", `${error.message} (cAL.A)`);
        });

      /**
       * --------------------------------------------------------------
       * tidak didalam statechange, karna ini global
       * get server time via firebase
       * --------------------------------------------------------------
       */
      db.ref("others/server-time").on("value", (response) => {
        if (response.val()) {
          let server = response.val();
          if (server) {
            this.$store.dispatch("ServerTime", { time: server.time });
          }
        }
      });

      /**
       * --------------------------------------------------------------
       * crontab every 1 minute
       * --------------------------------------------------------------
       */
      this.$crontab.addJob({
        name: "counter",
        interval: {
          seconds: "30",
        },
        job: this.handleCronTab,
      });
    },

    /**
     * --------------------------------------------------------------
     * to scroll ed of element chat, must be use timeout
     * tidak dapat manipulasi dom langsung
     * --------------------------------------------------------------
     */
    scrollToEndChat: function () {
      let elm = $("#chat");
      const messageLast = $(".conversation").children(".rw-chat").last();
      elm.animate(
        {
          scrollTop: elm[0].scrollHeight - (messageLast.height() + 50),
        },
        1000
      );
    },

    /**
     * --------------------------------------------------------------
     * for handle crontab
     * --------------------------------------------------------------
     */
    handleCronTab: function () {
      let varFallback = AppConfig.rwStorage.customer.fallback;
      let flagFallback = window.sessionStorage.getItem(varFallback);
      if (flagFallback === null || flagFallback === "0") {
        this.$store.dispatch("printDemo", `Handle crontab fallback 0 (cAL.B)`);
        /**
         * --------------------------------------------------------------
         * cek apakah token user di firebase masih valid ?
         * --------------------------------------------------------------
         */
        this.getFirebaseAuth();

        /* cocokkan time-server di firebase dan local*/
        this.getFirebaseTimeServer();
      } else {
        this.$store.dispatch(
          "printDemo",
          `cron : fallback sedang berlangsung (cAL.C)`
        );
      }
      return true;
    },

    /**
     * --------------------------------------------------------------
     * INSIDE CRONTAB
     * compare time in firebase server-time with localtime
     * --------------------------------------------------------------
     */
    getFirebaseTimeServer: function () {
      let vuex = this.getVuexGeneral._server_time; // server time
      let timeServer = vuex.time;
      let timeLocal = this.mixEpoch();
      // kalkulasi diff
      let prosesSelisih = vuex.isPlus
        ? timeLocal - vuex.diff
        : timeLocal + vuex.diff;
      // proses ambil diff local & server

      let diff = Math.abs(timeServer - prosesSelisih);
      let getDiffTime = AppConfig.diffAlertDCinSecond;
      this.showAlertDC =
        typeof getDiffTime !== "undefined" && diff > getDiffTime;
    },

    /**
     * --------------------------------------------------------------
     * INSIDE CRONTAB
     * request get firebase message agent, for make sure has been deliver to agent
     * --------------------------------------------------------------
     */
    getFirebaseAuth: function () {
      this.$store
        .dispatch("FbAuthIsLogin")
        .then((user) => {
          this.setFallbackStop();
          console.warn("Auth state", "Login");
          db.ref(`messages/${user.uid}`)
            .limitToLast(1)
            .get()
            .then((message) => {
              console.warn("Get message", "OK" + message.val());
            })
            .catch(() => {
              console.warn("Auth message", "Failed, relogin");
              // --- just for report monitoring ---
              this.$store.dispatch("sendLogAPI", {
                actor: "client",
                message: "Firebase auth state change, token expired",
              });
              let token = localStorage.getItem(
                AppConfig.rwStorage.customer.token
              );
              let jwt = this.jwtDecode(token);
              let fbAuth = {
                email: jwt.fb.uname,
                password: jwt.fb.sandi,
              };
              this.$store.dispatch("FbAuthLogin", fbAuth).then(() => {
                this.$store.dispatch("customerLogin", true);
                this.initCreated();
              });
            });
          /**
           * --------------------------------------------------------------
           * normal, account auth has logged
           * --------------------------------------------------------------
           */
        })
        .catch(() => {
          console.warn("Auth status 2 -> Failed, process relogin ");
          // --- just for report monitoring ---
          this.$store.dispatch("sendLogAPI", {
            actor: "client",
            message: "Firebase auth state change, token expired",
          });
          let token = localStorage.getItem(AppConfig.rwStorage.customer.token);
          let jwt = this.jwtDecode(token);
          let fbAuth = {
            email: jwt.fb.uname,
            password: jwt.fb.sandi,
          };
          this.$store.dispatch("FbAuthLogin", fbAuth).then(() => {
            this.setFallbackStop();
            this.$store.dispatch("customerLogin", true);
            this.initCreated();
          });
        }); // --- end catch firebase auth state chage ---
    },

    /**
     * --------------------------------------------------------------
     * handle message untuk update field ex. status
     * --------------------------------------------------------------
     */
    handleIncomingMessage: function (message) {
      this.$store.dispatch("printDemo", {
        NewMessage: message,
        cookies: this.cookiesGet(),
      });

      /**
       * --------------------------------------------------
       * if tipe = alert akan selalu update pilihdepartemen = null
       * asumsi sudah terhubung dg agent
       * --------------------------------------------------
       */
      if (message.tipe === "alert") {
        this.cookiesSyncUpdate({ pilihDepartemen: { uuid: null } });
        this.$store.dispatch("printDemo", {
          info: "update pilihDepartemen = null karna tipe = alert",
          data: message,
          extra: this.cookiesGet(),
        });
      }

      /**
       * --------------------------------------------------
       * handle action key workflow
       * --------------------------------------------------
       */
      if (message.tipe === "action") {
        this.cookiesSyncUpdate({ isChatAreaEnabled: false });
      }

      if (message.tipe === "chooseDepartemen") {
        this.cookiesSyncUpdate({ isContinueChatEnabled: false });
      }

      /**
       * --------------------------------------------------------------
       * if from agent or type IMAGE
       * --------------------------------------------------------------
       */
      if (
        message.actor !== "customer" ||
        ["image", "file"].includes(message.tipe)
      ) {
        this.mixAlert(message.name, message.message);

        if (
          message.tipe === "agent-close" &&
          typeof message.trigger === "undefined"
        ) {
          this.cookiesSyncUpdate({
            rating: { uuid: message.uuid, endtime: message.time },
            isVerified: false,
          });
        }

        if (
          ["common-question", "chooseDepartemen", "form"].includes(message.tipe)
        ) {
          let param = false;
          switch (message.tipe) {
            case "chooseDepartemen":
              param = { pilihDepartemen: { uuid: message.time } };
              break;
            case "form":
              param = { uuidForm: message.uuid };
              break;
          }
          this.cookiesSyncUpdate(param);
        }

        if (
          this.getAllMessage.findIndex((e) => e.uuid === message.uuid) === -1
        ) {
          this.$store.dispatch("MessagePush", message);
        } else {
          this.$store.dispatch("MessageUpdate", {
            index: this.getAllMessage.findIndex((e) => e.uuid === message.uuid),
            record: message,
          });
        }

        /**
         * --------------------------------------------------------------
         * trigger request agent info
         * agent-close : close / end thread
         * agent-leave : leave a thread
         * agent-join : added new agent
         * agent-connect : 1st message & re-open thread
         * --------------------------------------------------------------
         */
        let tipeReqAgentInfo = [
          "agent-close",
          "agent-leave",
          "agent-join",
          "agent-connect",
        ];
        if (tipeReqAgentInfo.includes(message.tipe)) {
          this.$store.dispatch("AgentInfo");
        }
      } else {
        /**
         * --------------------------------------------------------------
         * from CUSTOMER just update message
         * --------------------------------------------------------------
         */
        const messageIndex = this.getAllMessage.findIndex(
          (e) => e.uuid === message.uuid
        );
        this.$store.dispatch("MessageUpdate", {
          index: messageIndex,
          record: message,
        });
      }
    },

    selectEmoji: function (emot) {
      this.$store.dispatch("SetEmoji", {
        open: false,
        select: true,
        emotIcon: emot.data,
      });
    },

    /**
     * --------------------------------------------------------------
     * untuk trigger gesture sound supaya bunyi
     * kenapa localStorage : supaya terhapus setelah tutup browser
     * --------------------------------------------------------------
     */
    showAlertGestureSound() {
      if (typeof Storage !== "undefined") {
        let name = "rw:sound";
        let flag = localStorage.getItem(name);
        if (flag === null) {
          localStorage.setItem(name, "init");
        } else {
          this.$swal({
            title: "Warning",
            html: "Anda telah melakukan reload halaman.<br>Klik tombol dibawah ini untuk melanjutkan.",
            icon: "warning",
            confirmButtonText: "Lanjutkan",
          });

          let get = localStorage.getItem(name);
          if (get === "init") {
            localStorage.setItem(name, 1);
          } else {
            localStorage.setItem(name, parseInt(get) + 1);
          }
        }
      } else {
        this.$swal(
          "Warning",
          "Sorry, your browser does not support Web Storage",
          "error"
        );
      }
    },

    /**
     * --------------------------------------------------
     * request api fallback gagal login firebase
     * --------------------------------------------------
     */
    getFallbackNewMessage: function () {
      let flag = window.sessionStorage.getItem(
        AppConfig.rwStorage.customer.fallback
      );
      if (flag !== "1" || flag === null) {
        return;
      }
      this.API.post(this.URL.message.fallback, {
        lastid: this.cookiesGet("lastMsgId"),
      })
        .then(({ data }) => {
          let newMessage = data.data.newMessage;
          newMessage.forEach((message) => {
            this.handleIncomingMessage(message);
            this.cookiesAdd({ lastMsgId: message.id });
          });
        })
        .catch((error) => {
          return error;
        })
        .finally(() => {
          this.scrollToEndChat();
        });
    },

    /**
     * --------------------------------------------------
     * menstop fallback jika sudah berhasil login firebase dg cron
     * --------------------------------------------------
     */
    setFallbackStop: function () {
      clearInterval(this.fallbackInterval);
      window.sessionStorage.setItem(AppConfig.rwStorage.customer.fallback, "0");
    },
  },
  computed: {
    ...mapGetters([
      "getAllMessage",
      "getEmoji",
      "getAllVuexClient",
      "getVuexGeneral",
    ]),
  },
  mounted() {
    /**
     * --------------------------------------------------
     * proses interval fallback gagal firebase
     * --------------------------------------------------
     */
    let varFallback = AppConfig.rwStorage.customer.fallback;
    let flagFallback = window.sessionStorage.getItem(varFallback);
    if (flagFallback !== null && flagFallback === "1") {
      this.fallbackInterval = setInterval(() => {
        this.getFallbackNewMessage();
      }, AppConfig.intervalFallback);
    }

    // this.showAlertGestureSound();
    /**
     * --------------------------------------------------------------
     * sync cookies dengan vuex ketika refresh
     * and check cookies rating expired
     * --------------------------------------------------------------
     */
    let cookies = this.cookiesGet();
    let thresholdRating = 50400; // default 14hari
    if (typeof AppConfig.thresholdRatingInMinute === "number") {
      thresholdRating = AppConfig.thresholdRatingInMinute;
    }

    let ratingConfig = {};
    if (
      typeof cookies.rating.endtime !== "undefined" &&
      typeof cookies.rating.endtime === "number"
    ) {
      thresholdRating = thresholdRating * 60;
      let diff = this.mixEpoch() - cookies.rating.endtime;
      if (diff > thresholdRating) {
        ratingConfig = { rating: { uuid: null, endtime: null } };
      }
    } else {
      ratingConfig = { rating: { uuid: null, endtime: null } };
    }

    this.cookiesSyncUpdate(ratingConfig);
    this.$store.dispatch("AgentInfo");
    this.$store.dispatch("ConfigJs", this.cookiesGet());

    /**
     * --------------------------------------------------------------
     * scroll to chat last message
     * --------------------------------------------------------------
     */
    setTimeout(() => {
      this.scrollToEndChat();
    }, 600);
  },

  /**
   * --------------------------------------------------------------
   * created firebase for listen new message / cron
   * --------------------------------------------------------------
   */
  created() {
    /**
     * --------------------------------------------------------------
     * request server time
     * clientLastMessage : untuk sort time firebase
     * ServerTime : untuk cek connection
     * --------------------------------------------------------------
     */
    this.API.get(this.URL.config.customer)
      .then(({ data }) => {
        let server = data.time;
        this.$store.dispatch("clientLastMessage", { time: server });

        let local = this.mixEpoch();
        let time = {
          isPlus: server > local,
          diff: Math.abs(data.time - this.mixEpoch()), // convert to plus
        };
        this.$store.dispatch("ServerTime", time);
        this.initCreated();
      })
      .catch(() => {
        this.$swal({
          title: "Warning",
          html: "Please reload this page.",
          icon: "warning",
        }).then(({ isConfirmed }) => {
          if (isConfirmed) {
            this.$router.go();
          }
        });
      });
  },
};
</script>

<style>
.emoji-picker {
  position: absolute;
  z-index: 1;
  bottom: 4em;
  left: 2%;
}

#Emojis {
  overflow: auto;
  height: 153px;
}
.container-emoji {
  height: inherit;
  z-index: 100;
}
a {
  color: #00aae5;
}

.alert-dc {
  position: absolute;
  z-index: 500;
}
.alert-dc span {
  cursor: pointer;
}

#chat::-webkit-scrollbar {
  width: 0.1em;
}

#chat::-webkit-scrollbar-track {
  /*background-color: #e4e4e4;*/
  /*border-radius: 100px;*/
}

#chat::-webkit-scrollbar-thumb {
  /*border-radius: 100px;*/
  /*border: 6px solid rgba(0, 0, 0, 0.18);*/
  border-left: 0;
  border-right: 0;
  background-color: #16b5ec;
}
</style>
