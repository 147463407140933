<template>
  <div class="flex flex-col overflow-hidden h-full">
    <before-login v-if="!isLogin" :departmentStatus="departmentStatus" />

    <template v-if="isLogin">
      <after-login :departmentStatus="departmentStatus" />

      <template
        v-if="!getConfigJs.isClosed && !getConfigJs.isContinueChatEnabled"
      >
        <TextMessage
          v-if="getConfigJs.isTakeOver && getConfigJs.isChatAreaEnabled"
        />

        <div
          class="w-full flex justify-content-center bg-gray-100"
          v-if="getConfigJs.isVerified"
        >
          <div
            class="flex text-xs items-center text-success font-italic gap-1 pr-2 py-1"
          >
            <span class="material-symbols-outlined text-xs text-success"
              >verified</span
            >
            <span>Verified</span>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import AfterLogin from "@/components/customer/body/AfterLogin";
import BeforeLogin from "@/components/customer/body/BeforeLogin";
import TextMessage from "@/components/customer/sendMessage";
import { db } from "@/common/firebase/init";
import { waktu, request, alert } from "@/common/mixins/mix_helper";
import { departemenStatus } from "@/common/config";
import { mapGetters } from "vuex";

export default {
  name: "BodyBase",
  mixins: [waktu, request, alert],
  props: {
    isLogin: {
      required: true,
      type: Boolean,
    },
  },
  components: {
    AfterLogin,
    BeforeLogin,
    TextMessage,
  },
  data() {
    return {
      departmentStatus: departemenStatus,
    };
  },
  computed: {
    ...mapGetters(["getConfigJs"]),
  },
  created() {
    /**
     * --------------------------------------------------------------
     * listen firebase department status on/off
     * --------------------------------------------------------------
     */
    db.ref("others/department-status").on("value", (status) => {
      this.departmentStatus = status.val();
    });
  },
};
</script>
