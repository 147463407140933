<script>
import { request } from "@/common/mixins/mix_helper";
import { loader } from "@/common/mixins/mix_loader";
import { clientCookies } from "@/common/mixins/mix_cookies";
import { mapGetters } from "vuex";

export default {
  name: "LiveChatAgent",
  mixins: [clientCookies, loader, request],
  methods: {
    chatWithAgent() {
      this.loading();
      this.API.post(this.URL.client.liveAgent, {})
        .then(({ data }) => {
          this.cookiesSyncUpdate({ isTakeOver: true });
          this.$swal("Success", data.message, "success");
        })
        .catch(() => {
          this.$swal("error", "Gagal terhubung!", "error");
        })
        .finally(() => {
          this.loading(false);
        });
    },
  },
  computed: {
    ...mapGetters(["getConfigJs"]),
  },
};
</script>

<template>
  <div
    class="flex items-center justify-end w-full my-2 gap-1"
    v-if="!getConfigJs.isTakeOver && !getConfigJs.isClosed"
  >
    <span class="material-symbols-outlined text-sm text-success"
      >support_agent</span
    >
    <a href="#" @click.stop.prevent="chatWithAgent">
      <small class="">Chat dengan staf</small>
    </a>
  </div>
</template>
