<script>
import { mapGetters } from "vuex";
import ActionPaymentConfirmation from "@/components/customer/body/conversation/actions/ActionPaymentConfirmation.vue";
import ActionPaymentConfirmationCheck from "@/components/customer/body/conversation/actions/ActionPaymentConfirmationCheck.vue";
import ActionPinVerification from "@/components/customer/body/conversation/actions/ActionPinVerification.vue";
import ActionBillingInformation from "@/components/customer/body/conversation/actions/ActionBillingInformation.vue";

export default {
  name: "ActionPart",
  components: {
    ActionBillingInformation,
    ActionPinVerification,
    ActionPaymentConfirmationCheck,
    ActionPaymentConfirmation,
  },
  props: {
    message: {
      required: true,
    },
  },
  computed: {
    ...mapGetters(["getConfigJs"]),
  },
};
</script>

<template>
  <div>
    <ActionPaymentConfirmation
      :message="message"
      v-if="message.action_key === 'payment_confirmation'"
    />
    <ActionPaymentConfirmationCheck
      :message="message"
      v-if="message.action_key === 'payment_confirmation_check'"
    />
    <ActionPinVerification
      :message="message"
      v-if="message.action_key === 'pin_verification'"
    />
    <ActionBillingInformation
      :message="message"
      v-if="message.action_key === 'billing_information'"
    />
  </div>
</template>

<style scoped></style>
