<script>
import $ from "jquery";

export default {
  name: "JumpToBottom",
  data() {
    return {
      is_show: false,
      position: 0,
    };
  },
  methods: {
    toBottom() {
      const elm = this.elm;
      const messageLast = $(".conversation").children(".rw-chat").last();
      elm.animate(
        {
          scrollTop: elm[0].scrollHeight - (messageLast.height() + 50),
        },
        1000
      );
    },

    onScroll() {
      this.position = this.elm.scrollTop();
      const messageLast = $(".conversation").children(".rw-chat").last();
      this.is_show = this.position < messageLast.height();
    },
  },
  computed: {
    elm() {
      return $("#chat");
    },
  },
  mounted() {
    document.getElementById("chat").addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    document
      .getElementById("chat")
      .removeEventListener("scroll", this.onScroll);
  },
};
</script>

<template>
  <div class="absolute bottom-20" v-if="is_show">
    <div
      @click="toBottom"
      class="badge text-white d-flex justify-content-center align-items-center rw-bg-3 cursor-pointer"
    >
      <span class="material-symbols-outlined text-sm">south</span>
    </div>
  </div>
</template>

<style scoped></style>
